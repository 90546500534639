/**
 * card menu本地存储相关
 */
const WORKSPACE_EDIT_STATUS = "WORKSPACE_EDIT_STATUS";
const MENUS_BUTTONS_CACHE = "MENUS_BUTTONS_CACHE";
const MENUS_DATA_CACHE = "MENUS_DATA_CACHE";
export const STATUS_DEFAULT = "0";
export const STATUS_EDIT = "1";

export const getStorageEditStatus = () => {
  const status = localStorage.getItem(WORKSPACE_EDIT_STATUS);
  return status || STATUS_DEFAULT;
};

export const setStorageEditStatus = (status = STATUS_DEFAULT) => {
  localStorage.setItem(WORKSPACE_EDIT_STATUS, status);
};

export const getButtonPermission = (code: string) => {
  const menuButtonsCache = localStorage.getItem(MENUS_BUTTONS_CACHE) || "{}";
  try {
    const buttonPermission = JSON.parse(menuButtonsCache);
    return buttonPermission[code] || [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * 根据输入的code判断当前卡片是否是超级设备子应用的卡片，方便进行权限处理
 * @param code 
 */
export const isSuperDeviceCard = (code: string) => {
  const menuDataCache = localStorage.getItem(MENUS_DATA_CACHE) || "{}";
  try {
    const menuDataCacheObj = JSON.parse(menuDataCache);
    return menuDataCacheObj[code]?.startsWith('/kh');
  } catch (error) {
    console.error(error);
    return false;
  }
}
