/**
 * 处理菜单模式切换
 */
const MENU_SHOW_MODE = 'MENU_SHOW_MODE';
const DEFAULT_MODE = 'menu';
const OTHER_MODE = 'card';
// 导航模式：新版默认使用card导航模式，支持切换为menu导航模式
type navigationMode = 'menu' | 'card';

/**
 * 获取当前导航模式
 */
export const getNavMode = () => {
  // const showMode = localStorage.getItem(MENU_SHOW_MODE);
  // return showMode || DEFAULT_MODE;
  return DEFAULT_MODE; // 燃鸿项目写死menu模式
};

/**
 * 设置当前导航模式
 */
export const setNavMode = (mode: navigationMode = DEFAULT_MODE) => {
  localStorage.setItem(MENU_SHOW_MODE, mode);
};

/**
 * 切换导航模式
 */
export const swapNavMode = () => {
  const showMode = localStorage.getItem(MENU_SHOW_MODE);
  setNavMode(showMode === OTHER_MODE ? DEFAULT_MODE : OTHER_MODE);
};
