import { service as request } from "@/api/http";
import { BASE_SYSTEM_ENUM } from "@/utils/base";

class Service {
  // api request
  public requestCommon = (url: string, params: any) =>
    request(`${url}`, params);

  public requestCommonModel = ({ url = "", params = {} }) =>
    request(`${url}`, params);

  public initMenu = () =>
    request(`/${BASE_SYSTEM_ENUM.API_BASE}/card/getCardByUserId`);
}

export default Service;
