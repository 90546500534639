import { useEffect, useState } from "react";
import { Col, Row, Card, Button, Space, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";

// card-content
import { COMPONENT_MAP } from "@/components/Menu/Config/cardContent";

import { getSubAppUrl } from "@/config/microApp";
import actions from "@/stores/globalstate";

import { getButtonPermission, isSuperDeviceCard } from "@/components/Menu/Config/cardStorage";

import "./index.less";


type CardDataType = {
  size?: string;
  title?: string;
  content?: any[];
  colSpan?: string | number;
  btnConf?: any;
  btnList?: any[];
};
interface CardProps {
  name?: string;
  url?: string;
  code: string;
  id?: string;
  cardData?: string | CardDataType;
  buttons?: any[];
  from?: string; // workspace | cardLibrary
  size?: string;
  active?: boolean; // 当前卡片是否激活
  onActiveChange?: any;
  cardUpdateCode?: string;
}

const TWBaseMenuProCardPrefixCls = "tw-base-menu-pro-card";

const ProCard = (props: CardProps) => {
  const navigate = useNavigate();

  const { name = "", url, cardData, size: sizeProp, active = false, code, onActiveChange, cardUpdateCode } = props;

  const buttonPermission = getButtonPermission(code);

  const headStyle: any = {
    borderBottom: "none",
    padding: "0 16px",
    minHeight: "42px",
    fontSize: "18px",
  };
  const bodyStyle = {
    padding: "0",
  };

  let cardDataObject: CardDataType = {};
  if (cardData && typeof cardData === "string") {
    cardDataObject = JSON.parse(cardData);
  } else if (cardData && typeof cardData === "object") {
    cardDataObject = cardData;
  }

  const { size = sizeProp, title = name, content = [], btnConf = null, btnList = [] } = cardDataObject;

  const addProps = btnConf?.add;
  const viewProps = btnConf?.view;
  const handleBtnClick = (e: any, type = "add") => {
    e.stopPropagation();
    if (type === "add") {
      // 兼容处理 - hash模式方案
      if (url?.startsWith("/kh_")) {
        if (location.hash === "") {
          const truthUrl = url && getSubAppUrl(url);
          navigate(truthUrl);
          setTimeout(() => {
            actions.setGlobalState({
              cardBtnClick: {
                type,
                url,
                timestamp: +new Date(),
              },
            });
          }, 300);
        } else {
          actions.setGlobalState({
            cardBtnClick: {
              type,
              url,
              timestamp: +new Date(),
            },
          });
        }
      } else {
        // history模式方案
        const truthUrl = url && getSubAppUrl(url);
        if (truthUrl) {
          if (location.pathname === truthUrl) {
            actions.setGlobalState({
              cardBtnClick: {
                type,
                url,
                timestamp: +new Date(),
              },
            });
          } else {
            navigate(truthUrl);
            setTimeout(() => {
              actions.setGlobalState({
                cardBtnClick: {
                  type,
                  url,
                  timestamp: +new Date(),
                },
              });
            }, 300);
          }
        }
      }
    } else if (type === "view") {
      const truthUrl = url && getSubAppUrl(url);
      if (truthUrl) {
        if (location.pathname === truthUrl) {
          actions.setGlobalState({
            cardBtnClick: {
              type,
              url,
              timestamp: +new Date(),
            },
          });
        } else {
          navigate(truthUrl);
        }
      }
    }
    onActiveChange(code);
  };

  const handleBtnListClick = (e: any, params = {}) => {
    e.stopPropagation();
    onActiveChange(code);
    const truthUrl = url && getSubAppUrl(url);
    if (truthUrl) {
      if (!location.pathname.includes(truthUrl)) {
        navigate(truthUrl);
      }
      setTimeout(() => {
        actions.setGlobalState({
          cardBtnClick: {
            params,
            timestamp: +new Date(),
          },
        });
      }, 300);
    }
  };

  const handleCardClick = () => {
    if (!btnList.length && !addProps && !viewProps && url) {
      const truthUrl = getSubAppUrl(url);
      if (truthUrl) {
        navigate(truthUrl);
      }
      onActiveChange(code);
    }
  };

  const [w, h] = (size || "4x2").split("x");

  if (size === "4x2") {
    // 一行三个的卡片
    if (title.length > 4) {
      headStyle.fontSize = "12px";
    } else {
      headStyle.fontSize = "14px";
    }
  }
  const [updateKey, setUpdateKey] = useState<number | undefined>();

  useEffect(() => {
    if (cardUpdateCode && cardUpdateCode?.startsWith(code)) {
      setUpdateKey(+new Date());
    }
  }, [cardUpdateCode]);

  const showAddBtn = () => {
    const addBtn = (
      <Button
        onClick={(e) => handleBtnClick(e, "add")}
        key="addButton"
        type="primary"
        style={{ borderRadius: 0, width: addProps.width || 68 }}
        disabled={isSuperDeviceCard(code) && !buttonPermission.includes("add")}
      >
        {"新增"}
      </Button>
    );
    if (isSuperDeviceCard(code) && !buttonPermission.includes("add")) {
      return (
        <Tooltip title="暂无权限，请联系管理员">
          {addBtn}
        </Tooltip>
      )
    }
    return addBtn;
  }

  return (
    <Col span={Number(w) * 2}>
      <Card
        className={`${TWBaseMenuProCardPrefixCls} h-type-${h}` + (active ? " active" : "")}
        title={title}
        headStyle={headStyle}
        bodyStyle={bodyStyle}
        onClick={handleCardClick}
      >
        <Row align="middle" justify="center">
          {content.map((item, key) => {
            return (
              <Col span={item.colSpan || 24} className={`${TWBaseMenuProCardPrefixCls}-col`} key={key}>
                {(COMPONENT_MAP[item.componentName] &&
                  COMPONENT_MAP[item.componentName]({
                    ...item,
                    updateKey
                  })) ||
                  COMPONENT_MAP["Default"]()}
              </Col>
            );
          })}
        </Row>
        {/* 底部区域 */}
        <div
          className={`${TWBaseMenuProCardPrefixCls}-footer`}
          // 有特性卡片时调整样式
          style={{
            marginTop: content?.filter((item) => item.componentName === "Features")?.length ? 0 : "10px",
          }}
        >
          <Space>
            {btnList.map((item, index) => {
              const { type, text, width = 68, params, disabled = false } = item;
              return (
                <Button
                  onClick={(e) => handleBtnListClick(e, params)}
                  key={index}
                  type={type}
                  disabled={disabled}
                  style={{ borderRadius: 0, width }}
                >
                  {text || "按钮"}
                </Button>
              );
            })}
          </Space>

          {btnList.length === 0 && addProps && showAddBtn()}
          {btnList.length === 0 && viewProps && (
            <Button
              onClick={(e) => handleBtnClick(e, "view")}
              key="viewButton"
              style={{
                borderRadius: 0,
                width: viewProps.width || 68,
                marginLeft: 12,
              }}
            >
              {"查看"}
            </Button>
          )}
        </div>
      </Card>
    </Col>
  );
};

export default ProCard;
