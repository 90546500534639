/**
 * 上下布局的展示统计数值。
 */
import { useEffect, useState } from "react";
import { handleHttp } from "@/components/Menu/ProCard/utils/valueConfig";
import { DEFAULT_DATA } from "@/config";
import "./index.less";

export interface StatisticProps {
  title?: string;
  value?: string;
  valueStyle?: object;
  valueConfig?: any;
  storeData?: any;
  updateKey?: number | undefined;
}

const StatisticClassPrefix = "iot-component-statistic";

const Statistic = (props: StatisticProps) => {
  const {
    title,
    value = DEFAULT_DATA,
    valueStyle = {},
    valueConfig = null,
    storeData = {},
    updateKey,
  } = props;
  const [result, setResult] = useState(value);

  const updateData = () => {
    if (valueConfig) {
      if (
        valueConfig.type === "http" &&
        (result === DEFAULT_DATA || updateKey)
      ) {
        handleHttp(valueConfig, (resultIn: any) => {
          setResult(resultIn);
        });
      } else if (
        valueConfig.type === "store" &&
        !["", DEFAULT_DATA, undefined].includes(
          storeData[valueConfig.dataKey]
        ) &&
        (result === DEFAULT_DATA || updateKey)
      ) {
        setResult(storeData[valueConfig.dataKey]);
      }
    }
  };

  useEffect(() => {
    updateData();
  }, [updateKey]);

  return (
    <div className={`${StatisticClassPrefix}`}>
      <div className={`${StatisticClassPrefix}-content`} style={valueStyle}>
        {result}
      </div>
      <div className={`${StatisticClassPrefix}-title`}>{title}</div>
    </div>
  );
};

export default Statistic;
